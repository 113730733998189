import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";

const ProjectItem = ({ project }) => {
  const aboveMediumScreens = useMediaQuery("(min-width: 800px)");
  const [isHover, setHover] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const ref = useRef(null);

  // Handle links
  const handleClick = () => {
    window.location.href = project.url;
  };

  // Update cursor position on mouse move
  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();
    setCursorPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <>
      {aboveMediumScreens ? (
        /* DESKTOP */
        <motion.div
          className="relative flex items-center w-[140%] lg:pt-20"
          ref={ref}
        >
          {/* Project Details */}
          <div className="z-10 absolute">
            <p
              className="text-4xl lg:text-8xl lg:-ml-[20%] lg:absolute font-bold
              text-off-white md:absolute md:ml-11 font-satoshi"
            >
              {project.title}
            </p>
          </div>

          <motion.div
            className="relative flex justify-center items-center lg:ml-[35%] ml-[32%] h-[100%]"
            style={{
              width: "50vw",
              maxWidth: "900px",
              maxHeight: "900px",
            }}
            whileHover={{ cursor: "pointer" }}
            onClick={handleClick}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {/* Background Image */}
            <img
              src={project.img}
              alt={project.title}
              className="object-cover w-full h-full z-0 rounded-lg"
            />
            {/* Video */}
            <div className="absolute inset-0 flex justify-center items-center">
              <video
                loop
                autoPlay
                muted
                className="shadow-lg z-10 w-[56%] h-[86%] pointer-events-none bg-sky-49"
                width="210"
                src={project.video}
              >
                Your browser does not support this video.
              </video>
            </div>
          </motion.div>

          {/* View */}
          <motion.div
            className="z-50 flex absolute font-satoshi bg-blue rounded-full 
            w-[130px] h-[125px] justify-center items-center text-4xl"
            style={{
              top: `${cursorPosition.y - 82.5}px`,
              left: `${cursorPosition.x - 65}px`,
              position: "absolute",
              pointerEvents: "none",
            }}
            animate={{
              opacity: isHover ? 1 : 0,
              scale: isHover ? 1 : 0.5,
            }}
            transition={{
              scale: {
                type: "spring",
                stiffness: 300,
                damping: 30,
              },
              opacity: {
                duration: 0.3,
                ease: "easeInOut",
              },
            }}
          >
            View
          </motion.div>
        </motion.div>
      ) : (
        <>
          {/* MOBILE */}
          <div onClick={handleClick}>
            <motion.div
              whileHover={{ cursor: "pointer" }}
              className="relative flex justify-center items-center"
            >
              {/* Background Image */}
              <div className="relative flex justify-center items-center h-[400px] w-[300px]">
                <img
                  src={project.img}
                  alt={project.title}
                  className="absolute inset-0 object-cover w-[300px]
                h-[400px] z-0 rounded-lg"
                />

                {/* Video */}
                <div className="relative flex items-center justify-center h-[500px]">
                  <video
                    loop
                    autoPlay
                    muted
                    playsInline
                    className="shadow-lg z-10"
                    width="150"
                    src={project.video}
                  >
                    Your browser does not support this video.
                  </video>
                </div>
              </div>
            </motion.div>

            {/* Project Details */}
            <div className="z-10 mt-4 px-[24%] flex flex-col items-start">
              <p className="text-2xl lg:text-3xl text-off-white">
                {project.title}
              </p>
            </div>
            <div
              className="absolute text-[18px] text-gray-400 mt-1 ml-11 flex flex-col 
              px-2 py-[2px] bg-transparent border-gray-400 border rounded-full"
            >
              <span className="flex text-center">REACT</span>
            </div>
            <div
              className="absolute text-[18px] text-gray-400 mt-1 ml-[125px]
              px-2 py-[2px] bg-transparent border-gray-400 border rounded-full"
            >
              <span className="flex text-center">THREEJS</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectItem;
