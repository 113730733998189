import React from "react";
import { motion } from "framer-motion";

const AnimatedText = ({ text, className, ...props }) => {
  const word = text.split("");

  const container = {
    hidden: {
      opacity: 0,
    },
    visible: (i = 1) => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2 * i,
      },
    }),
  };

  const childVariants = {
    hidden: {
      opacity: 0,
      x: 20,
      transition: { type: "spring", damping: 12, stiffness: 100 },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", damping: 12, stiffness: 100 },
    },
  };

  return (
    <motion.div
      className={`mt-5 ${className}`}
      variants={container}
      initial="hidden"
      whileInView="visible"
      {...props}
    >
      {word.map((letter, index) => (
        <motion.span variants={childVariants} key={index}>
          {letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default AnimatedText;
